import React from 'react';
import Layout from '../../components/layout';
import HeroCTA from '../../components/sections/heroCTA/heroCTA';
import Segment from '../../components/segment';
import { Helmet } from 'react-helmet-async';
import Seo from '../../components/seo';
import ImageText from '../../components/sections/imageText/imageText';
import GetStartedST from '../../components/staticSections/GetStartedST/GetStartedST';
import BgCardsTextST from '../../components/staticSections/bgCardsTextST/BgCardsTextST';
import SocialProofFeedST from '@components/staticSections/socialProofFeedST/socialProofFeedST';
import LicensedPtSliderST from '../../components/staticSections/LicensedPtSliderST/LicensedPtSliderST';
import Table from '../../components/sections/table/table';
import OutcomeST from '../../components/staticSections/OutcomeST/OutcomeST';
import FaqST from '../../components/staticSections/FaqST/FaqST';
import TestimonialGridST from '../../components/staticSections/TestimonialGridST/TestimonialGridST';
import CtaST from '../../components/staticSections/CtaST/CtaST';
import Logo from '@assets/humana.svg';

const IndexPage = () => {
  return (
    <Layout
      lang="us"
      headerWitdh="header"
      topHeader
      text="Covered by Humana at no cost"
      showFooter
      headerImage={
        <img className="logo--md margin-left--xs flex" alt="Humana" src={Logo} />
      }
    >
      <Helmet>
        <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var
        f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NCWLPMM');`}</script>
      </Helmet>
      <Segment id={process.env.SEGMENT_MARKETING_LANDING_PAGES_US} />
      <Seo title="Humana" language="en" />
      <HeroCTA
        dataTheme="ice-blue"
        imagedata4
        dataSection="hero_CTA"
        alt="blue shield of california"
        title="Treat joint and back pain from anywhere"
        titleClassName="bold"
        text="Enter your information to download the Joint Academy app and get started with a personalized exercise program today"
        textClassName=""
        formSubtextClassname="margin-top--xs"
        formSubtext=""
        formHumanaGiftcard
        dataGoalGiftcard="Form Submitted"
      />
      <ImageText
        Filename="humana2.png"
        imgSize="col--lg-4"
        alt="In-network with Humana"
        title="In-network with Humana"
        text="Joint Academy is a covered benefit under most Humana plans at no cost to you. Download the Joint Academy app to confirm your coverage or contact support for further assistance."
        download
        downloaddataGoal="Onboarding Viewed"
        downloadhref="#herotext"
        downloadclassname="btn btn--primary btn--md btn--lgMob"
        downloadtext="get started"
      />
      <GetStartedST
        lang="us_en"
        renderDownloadButton
        ctaButtonText="Get Started"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="#herotext"
      />
      <BgCardsTextST dataTheme="light" lang="us_en" />
      <SocialProofFeedST lang="us_en" />
      <TestimonialGridST lang="us_en" />
      <LicensedPtSliderST
        lang="us_en"
        downloaddataGoal="Onboarding Viewed"
        downloadhref="#herotext"
        downloadclassname="btn btn--fullwidth btn--md btn--primary btn--lgMob"
        downloadtext="Get started today"
      />
      <Table
        title="Joint Academy vs. traditional treatment"
        text="Joint Academy is a digital treatment for chronic joint pain that might be beneficial you you compared to traditional face-to-face physical therapy available at clinics."
        images={[
          {
            alt: 'Price table large',
            Filename: 'price-table-large-humana.png',
            className: 'display--sm',
          },
          {
            alt: 'Price table small',
            Filename: 'price-table-small-humana.png',
            className: 'hide--sm',
          },
        ]}
        ctaButtonText="GET STARTED"
        ctaDataGoal="Onboarding Viewed"
        ctaHref="#herotext"
        subText={[
          {
            text: '**Average $30 copay per visit for an average of 4 visits per month.',
            className: '',
          },
        ]}
      />
      <OutcomeST lang="us_en" />
      <FaqST lang="us_en" />
      <CtaST
        lang="us_en"
        ctaDataGoal="Calendly Viewed"
        ctaHref="https://calendly.com/d/k6dx-mdtf/joint-academy-physical-therapy"
        ctaButtonText="Schedule Call"
      />
    </Layout>
  );
};

export default IndexPage;
